<template>
  <dashboard>
    <div class="profileDiv">
      <div class="container  animate__animated animate__fadeInRight">
        <div class="row">
          <div class="col col-12">
            <div class="title">
              <h1>Profili im</h1>
              <hr />
            </div>
          </div>

          <!-- Profile informations -->
          <div class="col col-12">
            <div class="mainBody">
              <form action="#" method="POST">
                <div class="col col-12">
                  <div class="image">
                    <img src="../../assets/Images/avatar.png" alt="" />
                    <input type="file" name="img" accept="image/*" />
                  </div>
                </div>
                <div class="inputs">
                  <div class="col col-12">
                    <label for="">Emri</label>
                    <input type="text" name="" id="" placeholder="Emri juaj" />
                  </div>
                  <div class="col col-12">
                    <label for="">Mbiemri</label>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Mbiemri juaj"
                    />
                  </div>
                  <div class="col col-12">
                    <label for="">Kompania</label>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Emri Kompanisë / Organizatës"
                    />
                  </div>
                  <div class="col col-12">
                    <label for="">Nr.Kontaktues</label>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Numri i telefonit"
                    />
                  </div>
                  <div class="col col-12">
                    <label for="">E-maili</label>
                    <input
                      type="email"
                      name=""
                      id=""
                      placeholder="E-maili personal"
                    />
                  </div>
                  <div class="col col-12">
                    <button type="submit">
                      <i class="fas fa-check"></i> Ruaj ndryshimet
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- Profile informations end -->

          <!-- Change password -->
          <div class="col col-12">
            <div class="mainBody">
              <form action="#" method="POST">
                <div class="inputs">
                  <div class="col col-12">
                    <label for="">Fjalëkalimi i ri</label>
                    <input
                      type="password"
                      name=""
                      id=""
                      placeholder="Shkruaje fjalëkalimin e ri"
                    />
                  </div>
                  <div class="col col-12">
                    <label for="">Rikonfirmo passwordin</label>
                    <input
                      type="password"
                      name=""
                      id=""
                      placeholder="Rishkruaje fjalëkalimin e ri"
                    />
                  </div>
                  <div class="col col-12">
                    <button type="submit">
                      <i class="fas fa-check"></i> Ruaj ndryshimet
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- Change password End -->
        </div>
      </div>
    </div>
  </dashboard>
</template>

<script>
import Dashboard from "../DashHome/dashboard.vue";
export default {
  components: {
    Dashboard,
  },
};
</script>
